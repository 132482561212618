import { request } from '@src/axios.ts'

export type DiaryTypeFile = {
  uuid: string
  fileType: 'image' | 'audio' | 'video'
}

export type DiaryType = {
  id: number
  type?: string
  theme: string
  text: string
  reaction: string | null
  userId: number
  isDraft?: boolean
  files?: DiaryTypeFile[]
  createdAt?: string
}

type DiariesData = {
  theme: string
  text: string
  isDraft: boolean
  fileIds: string[]
}

export default class DiariesResource {
  private resourceUrl = '/diaries'

  calendar(startDate: string, endDate: string) {
    return request<Record<string, {
      count: number
      uuid?: string
    }>>({
      method: 'get',
      url: `${this.resourceUrl}/calendar`,
      params: {
        startDate,
        endDate,
      },
    })
  }

  template() {
    return request<{
      title: string
      text: string
    }>({
      method: 'get',
      url: '/diary-template',
    })
  }

  create(data: DiariesData) {
    return request<DiaryType>({
      method: 'post',
      url: this.resourceUrl,
      data,
    })
  }

  list(search = '', page = 1, limit = 10) {
    return request<{
      page: number
      total: number
      diaries: DiaryType[]
      draftCount?: number
      nonDraftCount?: number
    }>({
      method: 'get',
      url: this.resourceUrl,
      params: {
        page,
        limit,
        search,
      },
    })
  }

  listByDate(date: string, page = 1, limit = 10) {
    return request<{
      page: number
      total: number
      diaries: DiaryType[]
    }>({
      method: 'get',
      url: this.resourceUrl,
      params: {
        date,
        page,
        limit,
      },
    })
  }

  update(id: number, data: DiariesData) {
    return request<DiaryType>({
      method: 'put',
      url: `${this.resourceUrl}/${id}`,
      data,
    })
  }

  delete(id: number) {
    return request<null>({
      method: 'delete',
      url: `${this.resourceUrl}/${id}`,
    })
  }

  setReaction(id: number, reaction: string | null) {
    return request<'true'>({
      method: 'put',
      url: `${this.resourceUrl}/reaction/${id}`,
      data: {
        emoji: reaction,
      },
    })
  }

  setDiaryTemplateSetting(isEnabled: boolean) {
    return request({
      method: 'put',
      url: '/diary-template-setting',
      data: {
        isEnabled,
      },
    })
  }

  getDiaryTemplateSetting() {
    return request<{
      isEnabled: boolean
    }>({
      method: 'get',
      url: '/diary-template-setting',
    })
  }

  getDayQuote() {
    return request<{
      isEnabled: boolean
      countNew: number
      quote: string
      author: string
    }>({
      method: 'get',
      url: `quotations/day-quot`,
    })
  }

  setDayQuote(isEnabled: boolean) {
    return request({
      method: 'put',
      url: `quotations/day-quot`,
      data: {
        isEnabled,
      },
    })
  }
}
