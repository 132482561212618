<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import { api } from '@src/api'
import { api as axios } from '@src/axios.ts'
import imgLoading from '@src/assets/img/loading.svg'
import 'vue3-carousel/dist/carousel.css'

type SlideItem = {
  uuid: string
  src: string | null
  isPremium: boolean
}

const slides = ref<SlideItem[]>([])

onMounted(() => {
  slides.value = []

  api.files.carouselFiles().then((items) => {
    slides.value = items.map((item) => ({
      uuid: item.file,
      src: null,
      isPremium: item.isPremium,
    }))

    slides.value.map((slide, index) => {
      api.files.getLink(slide.uuid, 'full')
        .then(({ link, expiresIn }) => {
          slides.value[index].src = expiresIn === 0 ? axios.defaults.baseURL + link : link
        })
    })
  })
})
</script>

<template>
  <Carousel :wrap-around="true">
    <Slide
      v-for="(slide, index) in slides"
      :key="index"
    >
      <div class="img-wrapper">
        <img
          v-if="slide.src === null"
          :src="imgLoading"
          alt=""
        />
        <img
          v-else
          :src="slide.src"
          alt=""
        />

        <div
          v-if="slide.isPremium"
          class="emotions-premium"
        ></div>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<style lang="scss" scoped>
.carousel {
  padding: 0 0 10px;
  text-align: center;

  :deep(.carousel__viewport) {
    padding-bottom: 12px;

    @media screen and (min-width: $desktop) {
      padding-bottom: 24px;
    }
  }

  .img-wrapper {
    position: relative;
    padding: 6px 6px 22px;
    width: 192px;
    height: 165px;
    border: 1.3px solid #010101;
    border-radius: 11px;
    background: #ffffff;
    box-sizing: border-box;

    @media screen and (min-width: $desktop) {
      padding: 12px 12px 44px;
      width: 384px;
      height: 330px;
      border: 2.6px solid #010101;
    }

    > div {
      --size: 34;
      position: absolute;
      right: 0;
      bottom: -12px;

      @media screen and (min-width: $desktop) {
        --size: 68;
        bottom: -24px;
      }
    }
  }

  img {
    width: 100%;
    border: .64px solid #010101;
    border-radius: 7.43px;
    object-fit: cover;

    @media screen and (min-width: $desktop) {
      border: 1.28px solid #010101;
    }
  }
}
</style>
