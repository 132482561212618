<script setup lang="ts">
import { inject, nextTick, onMounted, onUpdated, ref, watch } from 'vue'
import { format, isToday } from 'date-fns'
import WaveSurfer from 'wavesurfer.js'
import { api } from '@src/api'
import { api as axios } from '@src/axios.ts'
import { useAuthStore } from '@src/store/auth.ts'
import { LocalEventBus } from '@src/event-bus.ts'
import { DiaryType, DiaryTypeFile } from '@src/api/diaries.ts'
import AudioPlayer from '@src/components/AudioPlayer.vue'
import imgLoading from '@src/assets/img/loading.svg'

const props = defineProps<{
  item: DiaryType
  authorAvatarData: string | null
  isEnabled: boolean
  canEdit?: boolean
  canReaction?: boolean
}>()

const emit = defineEmits<{
  edit: [number]
  remove: [number, boolean]
  audioStart: [WaveSurfer]
  audioFinish: [number]
}>()

const types: Record<string, string> = {
  start: 'Начнем!',
  draft: 'черновик',
}

const emotions = [
  'emotions-red-heart',
  'emotions-heart-eyes',
  'emotions-loudly-crying-face',
  'emotions-screaming-face',
  'emotions-face-with-tears-of-joy',
  'emotions-broken-heart',
  'emotions-melting-face',
  'emotions-weary-face',
  'emotions-pleading-face',
  'emotions-star-struck',
  'emotions-sunglasses',
  'emotions-heart-hands',
  'emotions-raising-hands',
  'emotions-waving-hand',
  'emotions-folded-hands',
  'emotions-crying-cat',
  'emotions-angry-face',
  'emotions-neutral-face',
  'emotions-ok-hand',
  'emotions-fire',
  'emotions-birthday-cake',
  'emotions-direct-hit',
  'emotions-wrapped-gift',
  'emotions-balloon',
]

const audioPlayer = ref<typeof AudioPlayer | null>(null)
const authStore = useAuthStore()
const eventBus = inject(LocalEventBus)
const diary = ref<HTMLElement | null>(null)
const isEnabled = ref(true)
const info = ref<HTMLElement | null>(null)
const title = ref<HTMLElement | null>(null)
const content = ref<HTMLElement | null>(null)
const showPlace = ref<HTMLElement | null>(null)
const showLink = ref(false)
const showPost = ref(false)
const showReactionBlock = ref(false)
const isAudioDiary = ref(false)
const files = ref<(DiaryTypeFile & { link: string | null, processing: boolean })[]>([])

const changeEnabled = () => {
  api.diaries.setDiaryTemplateSetting(isEnabled.value)
  showPost.value = false
}

const handleShow = () => {
  if (!showPost.value) {
    document.querySelectorAll('.diary.show-full').forEach((item) => {
      const content = item.querySelector('.diary--content') as HTMLElement
      const link = item.querySelector('.diary--show') as HTMLElement

      content.classList.remove('animate')
      link.click()
      nextTick(() => {
        content.classList.add('animate')
      })
    })
  }

  showPost.value = !showPost.value

  if (showPost.value) {
    content.value?.style.setProperty(
      'max-height',
      content.value?.scrollHeight + 'px',
    )

    nextTick(() => {
      const topElement = document.querySelector('.top') as HTMLElement
      if (diary.value && diary.value.offsetTop - topElement.offsetHeight < document.documentElement.scrollTop) {
        nextTick(() => {
          window.scrollTo({
            top: diary.value!.offsetTop - topElement.offsetHeight,
            behavior: 'smooth',
          })
        })
      }
    })
  } else {
    content.value?.style.removeProperty('max-height')
  }
}

const calculateHeight = () => {
  if (content.value?.scrollHeight ?? 1 > 0) {
    showLink.value = content.value !== null && content.value?.scrollHeight > 85
  }
}

const outputDateTime = (dt: string) => {
  const date = new Date(dt)

  const output = [
    format(date, 'dd.MM.yyyy'),
    format(date, 'HH:mm'),
  ]

  if (isToday(date)) {
    output[0] = 'сегодня'
  }

  return output.join(', ')
}

const handleSlideshow = (uuid: string) => {
  eventBus?.emit('slideshow', {
    files: files.value.filter((item) => item.processing === false) ?? [],
    uuid,
  })
}

const handleShowReactionBlock = () => {
  if (props.canReaction) {
    if (props.item.reaction) {
      props.item.reaction = null
      api.diaries.setReaction(props.item.id, null)
    } else {
      showReactionBlock.value = true
    }
  }
}

const handleReaction = (emotion: string) => {
  showReactionBlock.value = false

  if (props.canReaction) {
    props.item.reaction = emotion
    api.diaries.setReaction(props.item.id, emotion)
  }
}

const audioStart = (player: WaveSurfer) => {
  emit('audioStart', player)
}

const audioFinish = () => {
  emit('audioFinish', props.item.id)
}

onMounted(() => {
  nextTick(() => {
    calculateHeight()
  })

  window.addEventListener('resize', () => {
    calculateHeight()
  })

  isAudioDiary.value = props.item.files?.length === 1 &&
                       props.item.files[0].fileType === 'audio'

  isEnabled.value = props.isEnabled
})

onUpdated(() => {
  nextTick(() => {
    calculateHeight()
  })
})

watch(
  () => props.item.files,
  (itemFiles) => {
    files.value = itemFiles?.map((file) => {
      return {
        ...file,
        link: null,
        processing: false,
      }
    }) ?? []

    files.value.map((file, index) => {
      api.files.getLink(file.uuid, file.fileType === 'audio' ? 'full' : 'thumbnail')
        .then(({ link, expiresIn, processing }) => {
          files.value[index].link = expiresIn === 0 ? axios.defaults.baseURL + link : link
          files.value[index].processing = processing
        })
    })
  },
  {
    immediate: true,
  }
)

const hidePost = () => {
  if (showPost.value) {
    content.value?.classList.remove('animate')
    handleShow()
    nextTick(() => {
      content.value?.classList.add('animate')
    })
  }
}

const play = () => {
  audioPlayer.value?.play()
}

defineExpose({
  hidePost,
  play,
})
</script>

<template>
  <div
    ref="diary"
    class="diary"
    :class="{ 'show-full': showPost }"
    :data-diary="item.id"
  >
    <div class="diary--data">
      <div
        ref="info"
        class="diary--info"
      >
        <div class="diary--info--left">
          <img
            v-if="authorAvatarData"
            class="diary--author"
            :class="{ round: item.id !== 0 }"
            :src="authorAvatarData"
            alt="Author"
          />
          <div
            v-else
            class="icon-avatar diary--author--icon"
          ></div>

          <div class="diary--timestamp">
            <template v-if="authStore.user && item.type === 'start'">
              {{ outputDateTime(authStore.user.createdAt) }}
            </template>
            <template v-else-if="item.createdAt">
              {{ outputDateTime(item.createdAt) }}
            </template>
            <template v-else>сегодня</template>
          </div>
        </div>

        <div
          v-if="item.type && types[item.type]"
          class="diary--info--status"
          :class="item.type"
        >
          {{ types[item.type] }}
        </div>

        <div
          v-if="item.id === 0"
          class="diary--info--right"
        >
          <input
            v-model="isEnabled"
            type="checkbox"
            id="diary-template"
            class="checkbox"
            @change="changeEnabled"
          />
          <label for="diary-template"></label>
        </div>
        <div
          v-else
          class="diary--info--right"
          :class="{ shift: item.reaction }"
        >
          <div
            v-if="canEdit && !isAudioDiary"
            class="icon-edit"
            @click="emit('edit', item.id)"
          ></div>

          <div
            v-if="canEdit"
            class="icon-trash"
            @click="emit('remove', item.id, item.isDraft ?? false)"
          ></div>
        </div>
      </div>

      <h4
        ref="title"
        class="diary--title"
      >
        {{ item.theme }}
      </h4>

      <div
        v-if="!isAudioDiary && isEnabled"
        ref="content"
        class="diary--content animate"
      >
        <p v-for="line in item.text.split('\n')">{{ line }}</p>
      </div>

      <div
        v-if="isEnabled"
        ref="showPlace"
        class="diary--show-place"
      >
        <div
          v-if="showLink"
          class="diary--show"
          @click.stop="handleShow"
        >
          <p>{{ showPost ? 'скрыть' : 'читать больше' }}</p>
          <div
            v-show="!showPost"
            class="icon-chevron-down"
          ></div>
          <div
            v-show="showPost"
            class="icon-chevron-up"
          ></div>
        </div>
      </div>

      <template v-if="isAudioDiary">
        <div class="diary--audio">
          <img
            v-if="files![0].link === null"
            :src="imgLoading"
            alt=""
          />
          <AudioPlayer
            v-else
            ref="audioPlayer"
            :url="files![0].link"
            @start="audioStart"
            @finish="audioFinish"
          />
        </div>
      </template>
      <template v-else>
        <div
          v-if="files && files.length > 0"
          class="diary--images"
        >
          <div
            v-for="(file) in files"
            :key="file.uuid"
            :class="file.fileType"
            @click="file.processing ? () => {} : handleSlideshow(file.uuid)"
          >
            <img
              :src="file.link ?? imgLoading"
              alt=""
            />

            <div
              v-if="file.processing"
              class="img-processing"
            >
              <img src="../assets/img/i-hourglass-white.svg" alt="..." />
            </div>
          </div>
        </div>
      </template>
    </div>

    <div
      class="diary--reaction"
      :class="{ show: canReaction, active: item.reaction }"
      @click="handleShowReactionBlock"
    >
      <div :class="item.reaction ?? 'emotions-red-heart'"></div>
    </div>

    <div
      v-if="canReaction"
      class="diary--reaction-block"
      :class="{ show: showReactionBlock }"
    >
      <div
        v-for="emotion in emotions"
        :key="emotion"
        :class="emotion"
        @click="handleReaction(emotion)"
      ></div>
    </div>
  </div>
</template>
