import { InjectionKey } from 'vue'
import { Emitter } from 'mitt'
import type { DiaryTypeFile } from '@src/api/diaries.ts'

export type LocalEvents = {
  mainMenu: 'show' | 'hide'
  topSearch: 'show' | 'hide'
  profileCode: 'show' | 'hide'
  profileCodeClose: 'close'
  editor: 'show' | 'close' | 'upload' | 'trash'
  diarySearch: 'hide'
  calendar: 'today'
  slideshow: {
    files: DiaryTypeFile[]
    uuid?: string
  }
  loading: 'show' | 'hide'
  clearSearch: 'clear'
}

export const LocalEventBus: InjectionKey<Emitter<LocalEvents>> =
  Symbol('localEventBus')
