import { defineStore } from 'pinia'
import { api } from '@src/api'
import { api as axios } from '@src/axios.ts'

export const useAvatarStore = defineStore('avatar', {
  state: (): {
    avatarData: string | null
  } => {
    return {
      avatarData: null,
    }
  },

  actions: {
    setAvatar(uuid: string) {
      api.files.getLink(uuid, 'full').then(({ link, expiresIn }) => {
        this.avatarData = expiresIn === 0 ? axios.defaults.baseURL + link : link
      })
    },
    clearAvatar() {
      this.avatarData = null
    },
  },
})
