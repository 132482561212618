<script setup lang="ts">
import { inject, onMounted, ref } from 'vue'
import { useAuthStore } from '@src/store/auth.ts'
import { useWindowStore } from '@src/store/window'
import TopHeader from '@src/components/TopHeader.vue'
import ChildTopHeader from '@src/components/child/TopHeader.vue'
import Menu from '@src/components/Menu.vue'
import Slideshow from '@src/components/Slideshow.vue'
import { LocalEventBus } from '@src/event-bus.ts'
import FullscreenLoading from '@src/components/FullscreenLoading.vue'
import type { DiaryTypeFile } from '@src/api/diaries.ts'

const authStore = useAuthStore()
const windowStore = useWindowStore()
const eventBus = inject(LocalEventBus)
const slideshowShow = ref(false)
const slideshowFiles = ref<DiaryTypeFile[]>([])
const slideshowUuid = ref<string>('')
const fullscreenShow = ref(false)

eventBus?.on('slideshow', ({ files, uuid }) => {
  slideshowFiles.value = files
  slideshowShow.value = true
  slideshowUuid.value = uuid ?? ''
})

eventBus?.on('loading', (action) => {
  fullscreenShow.value = action === 'show'
})

const handleClose = () => {
  slideshowShow.value = false
}

onMounted(() => {
  window.addEventListener('resize', () => {
    windowStore.changeWidth(window.innerWidth)
  })

  windowStore.changeWidth(window.innerWidth)
})
</script>

<template>
  <div class="bg-main"></div>
  <ChildTopHeader v-if="authStore.user?.role === 'child'" />
  <TopHeader v-else />
  <router-view />
  <Menu />

  <Slideshow
    v-if="slideshowShow"
    :files="slideshowFiles"
    :uuid="slideshowUuid"
    @close="handleClose"
  />

  <FullscreenLoading
    v-if="fullscreenShow"
  />
</template>

<style lang="scss" scoped>
.bg-main {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url('/images/bg-mobile.jpg') top center no-repeat;
  background-size: cover;
  z-index: -1;

  @media screen and (min-width: $desktop) {
    background-image: url('/images/bg-desktop.jpg');
  }
}
</style>
