<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { format } from 'date-fns'
import { api } from '@src/api'
import { api as axios } from '@src/axios.ts'
import { useAuthStore } from '@src/store/auth.ts'

// noinspection SpellCheckingInspection,LongLine
const avatarPixel =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
const months = [
  'январь',
  'февраль',
  'март',
  'апрель',
  'май',
  'июнь',
  'июль',
  'август',
  'сентябрь',
  'октябрь',
  'ноябрь',
  'декабрь',
]

const authStore = useAuthStore()

const avatarData = ref<string | null>(null)

const outputBirthday = () => {
  if (authStore.parent?.birthDate) {
    const birthday = new Date(authStore.parent.birthDate)

    return `${months[birthday.getMonth()]}, ${birthday.getFullYear()}`
  }

  return ''
}

const formatCreated = () => {
  if (authStore.parent?.createdAt) {
    return format(new Date(authStore.parent.createdAt), 'dd.MM.yyyy')
  }

  return ''
}

onMounted(() => {
  avatarData.value = null

  if (authStore.parent?.avatar) {
    api.files.getLink(authStore.parent.avatar, 'full')
      .then(({ link, expiresIn }) => {
        avatarData.value = expiresIn === 0 ? axios.defaults.baseURL + link : link
      })
  }
})
</script>

<template>
  <div class="profile">
    <div class="profile--main">
      <div class="profile--avatar">
        <img
          :src="avatarData ?? avatarPixel"
          :class="{ uploaded: avatarData }"
          alt="avatar"
        />
      </div>

      <div class="profile--private">
        <div class="profile--name">
          {{ authStore.parent?.fullName.split(' ')[0] }}
        </div>
      </div>
    </div>

    <div class="profile--data">
      <div class="profile--form">
        <h2 class="only-desktop">Профиль</h2>

        <div class="profile--info">
          <label>ФИО:</label>

          <div class="profile--info--value">
            {{ authStore.parent?.fullName }}
          </div>
        </div>

        <div
          v-if="authStore.parent?.birthDate"
          class="profile--info"
        >
          <label>Месяц и год рождения:</label>

          <div class="profile--info--value">
            {{ outputBirthday() }}
          </div>
        </div>

        <div class="profile--info">
          <label>Дневник создан:</label>

          <div class="profile--info--value">
            {{ formatCreated() }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.profile {
  position: relative;
  padding-top: 35px;
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
  min-height: calc(100dvh - 105px);
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    padding-top: 130px;
    flex-flow: row-reverse nowrap;
    min-height: 0;

    > div {
      flex: 0 0 560px;
    }
  }

  &--main {
    position: relative;
    margin-left: 45px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 14px;

    @media screen and (min-width: $desktop) {
      margin: 0;
      flex-flow: column nowrap;
      gap: 37px;
    }
  }

  &--avatar {
    position: relative;
    width: 100px;
    height: 100px;

    @media screen and (min-width: $desktop) {
      width: 250px;
      height: 250px;
    }

    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      background: url("/src/assets/img/avatar.gif") -130px -140px no-repeat;
      border-radius: 50%;
      overflow: hidden;

      @media screen and (max-width: $desktopM) {
        background-position-x: -50px;
        background-position-y: -57px;
        background-size: 200px;
      }

      &.uploaded {
        object-fit: cover;
        background: none;
      }
    }
  }

  &--private {
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;

    @media screen and (min-width: $desktop) {
      align-items: center;
      gap: 30px;
    }
  }

  &--name {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;

    @media screen and (min-width: $desktop) {
      font-size: 32px;
    }
  }

  &--subscribe {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    gap: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;

    @media screen and (min-width: $desktop) {
      gap: 30px;
      font-size: 15px;
      font-weight: 500;
    }

    img {
      height: 30px;

      @media screen and (min-width: $desktop) {
        height: 40px;
      }
    }

    span {
      color: #146fda;
    }

    &--data {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      gap: 4px;

      @media screen and (min-width: $desktop) {
        gap: 6px;
      }
    }
  }

  &--data {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    gap: 20px;
  }

  &--form {
    @media screen and (min-width: $desktop) {
      width: 335px;
    }

    h2 {
      text-align: center;
    }
  }

  &--info {
    margin-bottom: 20px;

    @media screen and (min-width: $desktop) {
      margin-bottom: 40px;
    }

    label {
      font-size: 12px;
      font-weight: 400;
    }

    &--value {
      margin-top: 12px;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.2;

      @media screen and (min-width: $desktop) {
        font-size: 20px;
        line-height: 1.2;
      }
    }
  }
}
</style>
