<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { api } from '@src/api'
import Validator, { Yup } from '@src/validator.ts'
import FormText from '@src/components/form/FormText.vue'
import FormArea from '@src/components/form/FormArea.vue'
import Popup from '@src/components/Popup.vue'
import DiarySearch from '@src/components/DiarySearch.vue'
import imgInfo from '@src/assets/img/img-info.gif'
import { api as axios } from '@src/axios.ts'

const suggestions = [
  'Здравствуй, Дорогой папочка!',
  'Я тебя искала!',
  'Я тебя Люблю!',
  'Очень жду от тебя весточку',
]

const route = useRoute()
const userId = parseInt(route.params.id as string)

const user = ref<{
  id: number
  fullName: string
  avatar: string | null
  avatarLink: string | null
  birthDate: string | null
  countDiaries: number
} | null>(null)
const validator = new Validator()
const isLoading = ref(false)
const email = ref('')
const name = ref('')
const message = ref('')
const type = ref<string>('сын')
const childSelector = ref(false)
const showPopup = ref(false)

const handleClickChildType = (typeName: string) => {
  type.value = typeName
  childSelector.value = false
}

const addToMessage = (text: string) => {
  message.value += text
}

const sendMessage = () => {
  if (!isLoading.value) {
    isLoading.value = true

    validator.validateAll().then((result) => {
      if (result) {
        api.users
          .sendMessageToUser({
            email: email.value,
            name: name.value,
            message: message.value,
            type: type.value,
            userId,
          })
          .then(() => {
            email.value = ''
            name.value = ''
            message.value = ''
            showPopup.value = true
          })
          .finally(() => {
            isLoading.value = false
          })
      } else {
        isLoading.value = false
      }
    })
  }
}

onMounted(() => {
  api.users.getSearchUser(userId).then((data) => {
    user.value = {
      ...data,
      avatarLink: null,
    }

    if (user.value.avatar) {
      api.files.getLink(user.value.avatar, 'thumbnail')
        .then(({ link, expiresIn }) => {
          user.value!.avatarLink = expiresIn === 0 ? axios.defaults.baseURL + link : link
        })
    }
  })
})
</script>

<template>
  <main>
    <div class="form-block">
      <div class="form-wrapper">
        <div class="top-text">
          Вы можете послать весточку!
          <span class="emotions-fire"></span>
          <span class="emotions-red-heart"></span>
        </div>

        <div class="only-mobile">
          <div
            v-if="user === null"
            class="diary-search"
          >
            <div class="diary-search--info">
              <img
                src="../assets/img/loading.svg"
                alt="Loading"
              />
            </div>
          </div>
          <DiarySearch
            v-else
            :item="user"
          />
        </div>

        <form ref="form">
          <FormText
            v-model="email"
            :ref="(el: typeof FormText) => validator.addItem('email', el)"
            id="email"
            type="email"
            :rules="Yup.string().email().required()"
            placeholder="Ваш Email"
          />
          <FormText
            v-model="name"
            :ref="(el: typeof FormText) => validator.addItem('name', el)"
            id="subject"
            type="text"
            :rules="Yup.string().required()"
            placeholder="Ваше имя"
          >
            <div class="child-type">
              <div
                class="child-type--name"
                @click="childSelector = true"
              >
                {{ type }}
                <span class="icon-chevron-down"></span>
              </div>

              <div
                v-show="childSelector"
                class="child-type--list"
              >
                <template v-if="type === 'сын'">
                  <div
                    class="child-type--item active"
                    @click="handleClickChildType('сын')"
                  >
                    сын
                    <span class="icon-chevron-up"></span>
                  </div>
                  <div
                    class="child-type--item"
                    @click="handleClickChildType('дочь')"
                  >
                    дочь
                  </div>
                </template>
                <template v-else>
                  <div
                    class="child-type--item active"
                    @click="handleClickChildType('дочь')"
                  >
                    дочь
                    <span class="icon-chevron-up"></span>
                  </div>
                  <div
                    class="child-type--item"
                    @click="handleClickChildType('сын')"
                  >
                    сын
                  </div>
                </template>
              </div>
            </div>
          </FormText>
          <FormArea
            v-model="message"
            :ref="(el: typeof FormArea) => validator.addItem('message', el)"
            id="message"
            :rules="Yup.string().required()"
            placeholder="Сообщение"
          />

          <div class="suggestions">
            <div
              v-for="suggestion in suggestions"
              class="suggestions--item"
              @click="addToMessage(suggestion)"
            >
              {{ suggestion }}
            </div>
          </div>
        </form>
      </div>

      <div class="actions">
        <div
          class="btn primary"
          :class="{ loading: isLoading }"
          @click="sendMessage"
        >
          Отправить
        </div>
      </div>
    </div>

    <div class="only-desktop">
      <div
        v-if="user === null"
        class="diary-search"
      >
        <div class="diary-search--info">
          <img
            src="../assets/img/loading.svg"
            alt="Loading"
          />
        </div>
      </div>
      <DiarySearch
        v-else
        :item="user"
      />
    </div>
  </main>

  <Popup
    v-model="showPopup"
    :image="imgInfo"
  >
    <h3 class="h1 only-desktop">Отправлено!</h3>
    <h3 class="h2 only-mobile">Отправлено!</h3>

    <div
      class="btn primary"
      @click="showPopup = false"
    >
      Ок
    </div>
  </Popup>
</template>

<style scoped lang="scss">
main {
  padding-top: 25px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: calc(100dvh - 105px);
  box-sizing: border-box;

  @media screen and (min-width: $desktop) {
    padding-top: 126px;
    align-items: center;
    min-height: 0;

    > div {
      flex: 0 0 560px;
    }
  }
}

.top-text {
  text-align: center;
  font-size: 15px;
  font-weight: 500;

  @media screen and (min-width: $desktop) {
    margin: 0 60px;
    font-size: 30px;
    line-height: 1.2;
  }

  span {
    --size: 24;

    @media screen and (min-width: $desktop) {
      --size: 40;
    }
  }
}

.diary-search {
  box-shadow: 0 20px 40px 0 #cad9efcc;
  cursor: default;

  @media screen and (min-width: $desktop) {
    padding: 40px 190px 40px 30px;
  }

  :deep(.diary-search--info) {
    @media screen and (min-width: $desktop) {
      min-height: 140px;
    }

    img {
      margin: 0 auto;
      display: block;
      width: 50px;
    }
  }

  :deep(.diary-search--main) {
    @media screen and (min-width: $desktop) {
      width: 320px;
    }
  }

  :deep(.diary-search--name) {
    @media screen and (min-width: $desktop) {
      font-size: 32px;
      font-weight: 500;
      line-height: 1.2;
      text-align: left;
    }
  }

  :deep(.diary-search--birthday) {
    @media screen and (min-width: $desktop) {
      margin-top: 16px;
      font-size: 17px;
      font-weight: 400;
      line-height: 1;
    }
  }

  :deep(.diary-search--count) {
    @media screen and (min-width: $desktop) {
      margin-top: 20px;
      font-size: 24px;
      font-weight: 500;
      line-height: 1;
      gap: 20px;

      span {
        --size: 32px;
      }
    }
  }

  > :deep(img) {
    top: -23px;
    width: 165px;
    height: 165px;
    border: 5px solid #ffffff;
    box-sizing: border-box;

    @media screen and (min-width: $desktop) {
      top: -15px;
      right: -60px;
      width: 250px;
      height: 250px;
      border-width: 10px;
    }
  }
}

.form-block {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: $desktop) {
    align-items: center;
  }

  h2 {
    margin-bottom: 20px;
    text-align: center;

    @media screen and (min-width: $desktop) {
      margin-bottom: 40px;
    }
  }

  .form-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 60px;
  }

  form {
    text-align: center;

    img {
      margin: 0 auto 30px;
      display: block;
      width: 160px;
      max-width: 100%;
    }

    .field-wrapper {
      display: inline-block;
      width: 100%;

      @media screen and (min-width: $desktop) {
        width: 335px;
      }
    }

    :deep(textarea) {
      max-height: 107px;
    }
  }

  .actions {
    margin-top: 30px;

    @media screen and (min-width: $desktop) {
      margin-top: 60px;
    }
  }

  .btn {
    margin: 0 auto;
    display: block;
    width: 255px;
    max-width: 100%;
  }
}

.suggestions {
  margin-top: 10px;
  display: inline-flex;
  flex-flow: row wrap;
  gap: 12px;

  @media screen and (min-width: $desktop) {
    margin-top: 20px;
    width: 335px;
  }

  &--item {
    padding: 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    background: #f0f7ff;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }
}

.popup--content {
  h3 {
    margin-top: 0;
    margin-bottom: 40px;
    text-align: center;

    @media screen and (min-width: $desktop) {
      margin-bottom: 50px;
    }
  }

  .content {
    font-size: 14px;
  }

  .btn {
    margin-top: 40px;

    @media screen and (min-width: $desktop) {
      margin-bottom: 50px;
    }
  }
}

.child-type {
  position: absolute;
  top: 13px;
  right: 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  z-index: 1;

  span[class^='icon-'] {
    font-size: 24px;
  }

  &--name,
  &--item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  &--name {
    color: #015bb0;
  }

  &--list {
    position: absolute;
    top: -17px;
    left: -25px;
    padding: 20px;
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    background: #ffffff;
    border: 1px solid #e7f4f9;
    border-radius: 10px;
    box-shadow: 0 20px 40px 0 #cad9ef66;
  }

  &--item {
    &.active {
      color: #015bb0;
    }
  }
}
</style>
