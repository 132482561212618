<script setup lang="ts">
import imgLoading from '@src/assets/img/loading.svg'

const months = [
  'январь',
  'февраль',
  'март',
  'апрель',
  'май',
  'июнь',
  'июль',
  'август',
  'сентябрь',
  'октябрь',
  'ноябрь',
  'декабрь',
]

const props = defineProps<{
  item: {
    id: number
    fullName: string
    avatar: string | null
    avatarLink: string | null
    birthDate: string | null
    countDiaries: number
  }
}>()

const calculateBirthday = () => {
  if (props.item.birthDate === null) {
    return ''
  }

  const birthday = new Date(props.item.birthDate)
  const now = new Date()

  if (now.getTime() < birthday.getTime()) {
    return ''
  }

  let year = now.getFullYear() - birthday.getFullYear() - 1

  if (now.getMonth() > birthday.getMonth()) {
    year += 1
  } else if (now.getMonth() === birthday.getMonth()) {
    if (now.getDate() >= birthday.getDate()) {
      year += 1
    }
  }

  return [
    year,
    pluralize(year, ['год', 'года', 'лет']),
    `(${months[birthday.getMonth()]}, ${birthday.getFullYear()})`,
  ].join(' ')
}

const pluralize = (count: number, words: string[]): string => {
  const cases = [2, 0, 1, 1, 1, 2]
  return words[
    count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]
  ]
}
</script>

<template>
  <div class="diary-search">
    <div class="diary-search--info">
      <div class="diary-search--main">
        <div class="diary-search--name">
          {{ item.fullName }}
        </div>

        <div class="diary-search--birthday">
          {{ calculateBirthday() }}
        </div>
      </div>

      <div class="diary-search--count">
        <span class="icon-book-edit"></span>
        {{ item.countDiaries }}
      </div>
    </div>

    <template v-if="item.avatar">
      <img
        :src="item.avatarLink ?? imgLoading"
        alt="Avatar"
      />
    </template>
    <template v-else>
      <img
        src="../assets/img/no-avatar.svg"
        alt="Avatar"
      />
    </template>
  </div>
</template>
